export const rootPath = '/strip-production/strip-lot-records/strip-planning';
export const entityPath = '/strip-production/strip-lot-records/strip-planning/:id';

export const addTitle = 'New Planning Input Record';
export const editTitle = 'Editing Strip Plan';
export const listTitle = 'Strip Plans';
export const addButtonText = 'New Strip Plan';
export const i18Namespace = 'StripPlan';
export const defaultSort = '';

export const addPermission = 'LDX.StripProduction.RecordProduction';
