export const rootPath = '/strip-production/finance-forecast-upload';
export const entityPath = '/strip-production/finance-forecast-upload/:id';

export const addTitle = 'Add Finance Forecast Upload';
export const editTitle = 'Editing Finance Forecast Upload';
export const listTitle = 'Finance Forecast Uploads';
export const addButtonText = 'New Finance Forecast Upload';
export const i18Namespace = 'FinanceForecastUpload';
export const defaultSort = '';

export const addPermission = 'LDX.StripProduction.Admin';
