import React from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { DialogState } from 'modules/Common/types';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grow,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { closeDialog } from 'modules/Core/actions/dialogs';
import { getUserDisplayName } from 'modules/Core/reducers/selectors';
import { useApiError, useDialogState } from 'modules/Core/hooks';
import { SummaryPanelItem } from 'modules/Common';
import { RSAAAction } from 'redux-api-middleware';

type SignOffDialogProps = {
  title: string;
  action: RSAAAction;
  api: string;
  dialogName: string;
  review?: { title: string; value: string | number | symbol }[];
  reviewText?: string;
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false;
};

const SignOffDialog = ({
  title,
  action,
  api,
  dialogName,
  review,
  reviewText,
  maxWidth,
}: SignOffDialogProps) => {
  const name = dialogName ?? 'pilot/batch-records/SIGN_OFF';
  const dispatch = useDispatch();
  const apiError = useApiError(api);
  const dialogOpen = useDialogState(name) === DialogState.Open;
  const userDisplayName = useSelector(getUserDisplayName);

  const handleClose = () => dispatch(closeDialog(name));
  const handleSign = () => dispatch(action);

  return (
    <Dialog open={dialogOpen} maxWidth={maxWidth} onClose={handleClose} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {apiError && (
          <Grow in={apiError && !apiError.validationErrors}>
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {apiError.message}
            </Alert>
          </Grow>
        )}
        <Grid container>
          {review &&
            review.map(item => (
              <SummaryPanelItem
                key={item.title}
                primary={item.title}
                secondary={item.value}
                secondaryVariant="subtitle1"
              />
            ))}
          {reviewText && <Typography variant="subtitle1">{reviewText}</Typography>}
        </Grid>
        <Divider />
        <Grid container>
          <SummaryPanelItem
            primary="Sign As"
            secondary={userDisplayName}
            secondaryVariant="subtitle1"
          />
          <SummaryPanelItem
            primary="Date"
            secondary={moment().format('D MMM YYYY, HH:mm')}
            secondaryVariant="subtitle1"
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained">
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={handleSign}>
          Sign
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SignOffDialog;
