export const rootPath = '/strip-production/strip-lot-records/kits';
export const entityPath = '/strip-production/strip-lot-records/kits/:id';

export const addTitle = 'New Kit Input Record';
export const editTitle = 'Editing Kit';
export const listTitle = 'Kits';
export const addButtonText = 'New Kit';
export const i18Namespace = 'Kits';
export const defaultSort = '';

export const addPermission = 'LDX.StripProduction.RecordProduction';
