import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './containers/Home';
import BufferTypeHome from '../BufferType/containers/Home';
import StripPlanHome from '../StripPlanning/containers/Home';
import KitHome from '../Kits/containers/Home';
import { rootPath } from './constants';
import { rootPath as bufferTypePath } from '../BufferType/constants';
import { rootPath as stripPlanPath } from '../StripPlanning/constants';
import { rootPath as kitsPath } from '../Kits/constants';
import FinanceForecastUploadHome from '../FinanceForecastUpload/containers/Home';
import { rootPath as financeForecastUploadPath } from '../FinanceForecastUpload/constants';

const routes = () => {
  return (
    <>
      <Switch>
        <Route path={kitsPath}>
          <KitHome />
        </Route>
        <Route path={stripPlanPath}>
          <StripPlanHome />
        </Route>
        <Route path={rootPath}>
          <Home />
        </Route>
        <Route path={bufferTypePath}>
          <BufferTypeHome />
        </Route>
        <Route path={financeForecastUploadPath}>
          <FinanceForecastUploadHome />
        </Route>
      </Switch>
    </>
  );
};

routes.path = '/strip-production';

export { entityPath } from './constants';
export default routes;
